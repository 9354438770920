import React, { useMemo } from "react";

const LaporanForPrint = ({ data, items, itemsBelanja }) => {
  const columns = useMemo(() => {
    if (data.length !== 0) {
      const col = [];
      items.forEach((item, i) => {
        col.push({
          Header: data?.daftar_laporan_site?.[0][item].header,
          accessor: data.daftar_laporan_site[0][item].accessor,
          align:
            data.daftar_laporan_site[0][item].accessor === "site"
              ? "left"
              : "center",
        });
      });
      return col;
    }
  }, [data, items]);

  const columnsDaftarBelanja = useMemo(() => {
    if (data.length !== 0) {
      const col = [];
      itemsBelanja.forEach((item, i) => {
        col.push({
          Header: data?.daftar_belanja?.[0]?.[item]?.header,
          accessor: data?.daftar_belanja?.[0]?.[item]?.accessor,
          align:
            data?.daftar_belanja?.[0]?.[item]?.accessor === "site"
              ? "left"
              : "center",
        });
      });
      return col;
    }
  }, [data, itemsBelanja]);

  const rowsDaftarBelanja = useMemo(() => {
    if (data.length !== 0) {
      const rows = [];
      let obj = {};
      for (let i = 0; i < data.daftar_belanja.length; i++) {
        for (let j = 0; j < itemsBelanja.length; j++) {
          obj[data?.daftar_belanja?.[i]?.[itemsBelanja[j]]?.accessor] =
            data.daftar_belanja[i][itemsBelanja[j]]?.rowBody;
        }
        rows.push({ ...obj });
      }
      return rows;
    }
  }, [data, itemsBelanja]);

  const rows = useMemo(() => {
    if (data.length !== 0) {
      const rows = [];
      let obj = {};
      for (let i = 0; i < data.daftar_laporan_site.length; i++) {
        for (let j = 0; j < items.length; j++) {
          obj[data.daftar_laporan_site[i][items[j]].accessor] =
            data.daftar_laporan_site[i][items[j]].rowBody;
        }
        rows.push({ ...obj });
      }
      return rows;
    }
  }, [data, items]);

  return (
    <div
      style={{ padding: 80, marginTop: 80 }}
      className="hidden"
      id="printarea"
    >
      <h1
        className=""
        style={{
          color: "#344767",
          fontWeight: 700,
          fontSize: 35,
        }}
      >
        ES KRISTAL PRATAMA
      </h1>
      <h3
        style={{
          color: "#344767",
          fontWeight: 700,
          fontSize: 25,
        }}
      >
        {data?.tanggal}
      </h3>
      <h4
        style={{
          color: "#7B809A",
          fontWeight: 400,
          fontSize: 16,
        }}
      >
        Tanggal & Waktu: {data?.tanggal_cetak}
      </h4>
      <h4
        style={{
          color: "#7B809A",
          fontWeight: 400,
          fontSize: 16,
          marginBottom: 60,
        }}
      >
        Nama Site: {data?.site_nama}
      </h4>
      <h5
        style={{
          color: "#344767",
          fontWeight: 500,
          fontSize: 20,
          marginBottom: 25,
        }}
      >
        Daftar Belanja
      </h5>
      <table>
        <thead>
          <tr className="p-0 m-0">
            {columnsDaftarBelanja?.map((item, i) => (
              <th
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  color: "#7B8098",
                  textAlign: "left",
                  paddingBottom: 10,
                  borderBottom: "1px solid #DEE2E6",
                }}
                className="text-[10px] font-600 text-[#7B8098] p-0 text-left"
                key={i}
              >
                {item.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rowsDaftarBelanja?.map((item, i) => (
            <tr key={i}>
              {columnsDaftarBelanja.map((col) => (
                <td
                  className="w-[150px]"
                  style={{
                    width: 150,
                    color: "#344767",
                    fontSize: 10,
                    fontWeight: 700,
                    paddingTop: 20,
                  }}
                  key={col.accessor}
                >
                  {item[col.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <h5
        style={{
          color: "#344767",
          fontWeight: 500,
          fontSize: 20,
          marginBottom: 25,
        }}
      >
        Laporan
      </h5>
      <table>
        <thead>
          <tr className="p-0 m-0">
            {columns?.map((item, i) => (
              <th
                style={{
                  fontSize: 10,
                  fontWeight: 600,
                  color: "#7B8098",
                  textAlign: "left",
                  paddingBottom: 10,
                  borderBottom: "1px solid #DEE2E6",
                }}
                className="text-[10px] font-600 text-[#7B8098] p-0 text-left"
                key={i}
              >
                {item.Header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((item, i) => (
            <tr key={i}>
              {columns.map((col) => (
                <td
                  className="w-[150px]"
                  style={{
                    width: 150,
                    color: "#344767",
                    fontSize: 10,
                    fontWeight: 700,
                    paddingTop: 20,
                  }}
                  key={col.accessor}
                >
                  {item[col.accessor]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LaporanForPrint;
