import Icon from "@mui/material/Icon";
import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useMemo, useState } from "react";
import FormAturTanggal from "./FormAturTanggal";
import { getData } from "utils/fetch";
import { toast } from "react-toastify";
import LaporanForPrint from "./LaporanForPrint";
import MDButton from "components/MDButton";

const Laporan = () => {
  const [tanggal, setTanggal] = useState([]);
  const [data, setData] = useState([]);
  const [dataPrint, setDataPrint] = useState({});
  const [items, setItems] = useState([]);
  const [form, setForm] = useState({
    bulan: "",
    tahun: "",
  });
  const [params, setParams] = useState({
    bulan: "",
    tahun: "",
  });
  const [isTampilkan, setIsTampilkan] = useState(false);

  useEffect(() => {
    if (isTampilkan) {
      const fetch = async () => {
        try {
          const response = await getData(`/api/super_admin/laporan`, params);
          setTanggal(response.tanggal);
          setDataPrint(response);
          const _temp = [...response.daftar_laporan_site];
          let _tempItem;
          _temp.forEach((item) => {
            _tempItem = Object.keys(item);
          });
          setItems(_tempItem);
          setData(response.daftar_laporan_site);
          if (!response.daftar_laporan_site.length) {
            toast.error("Tidak ada data.");
          }
        } catch (error) {
          toast.error(`${error.response.message}`);
          setIsTampilkan(false);
        }
      };
      fetch();
    }
    setIsTampilkan(false);
  }, [isTampilkan]);

  const columns = useMemo(() => {
    if (data.length !== 0) {
      const col = [];
      items.forEach((item, i) => {
        col.push({
          Header: data[0][item].header,
          accessor: data[0][item].accessor,
          align: data[0][item].accessor === "site" ? "left" : "center",
        });
      });
      return col;
    }
  }, [data]);

  const rows = useMemo(() => {
    if (data.length !== 0) {
      const rows = [];
      let obj = {};
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < items.length; j++) {
          obj[data[i][items[j]].accessor] = (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color={data[i][items[j]].warning ? "primary" : "text"}
              fontWeight="medium"
            >
              {data[i][items[j]].rowBody}
            </MDTypography>
          );
        }
        rows.push({ ...obj });
      }
      return rows;
    }
  }, [data]);

  const handleClick = () => {
    var content = document.getElementById("printarea");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    document.title = `Laporan ${dataPrint.tanggal}`;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <iframe
        id="ifmcontentstoprint"
        style={{
          height: "0px",
          width: "0px",
          position: "absolute",
        }}
      ></iframe>
      <MDBox py={3} className="relative">
        {Object.keys(dataPrint).length !== 0 &&
          dataPrint?.daftar_laporan_site?.length !== 0 && (
            <>
              <LaporanForPrint data={dataPrint} items={items} />
            </>
          )}
        <Grid container spacing={6}>
          <Grid item xs={12} lg={7}>
            <Card>
              <MDBox py={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium">
                  Atur Tanggal
                </MDTypography>
              </MDBox>
              <MDBox pb={2} px={2}>
                <MDBox
                  component="ul"
                  display="flex"
                  flexDirection="column"
                  p={0}
                  m={0}
                >
                  <FormAturTanggal
                    form={form}
                    setForm={setForm}
                    setIsTampilkan={setIsTampilkan}
                    setParams={setParams}
                  />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          {data.length !== 0 && (
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <div className="flex justify-between items-center">
                    <MDTypography
                      variant="h6"
                      color="white"
                      sx={{ fontWeight: 300, fontSize: "16px" }}
                    >
                      Table Laporan
                      <span className="font-700 ml-2">{tanggal}</span>
                    </MDTypography>
                  </div>
                </MDBox>

                <MDBox pt={3} pb={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                  <MDBox pt={2} px={2} className="hidden lg:block text-center">
                    <MDButton
                      // variant="outlined"
                      size="medium"
                      color="light"
                      className="uppercase"
                      onClick={handleClick}
                    >
                      <Icon>print</Icon>&nbsp; Cetak Laporan
                    </MDButton>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Laporan;
