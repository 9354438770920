/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import AksesPengguna from "layouts/aksesPengguna";

// @mui icons
import Icon from "@mui/material/Icon";
import Site from "layouts/site";
import Produk from "layouts/produk";
import Laporan from "layouts/laporan";
import DaftarLaporan from "layouts/daftarLaporan";
import LaporanHarian from "layouts/laporanHarian";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import InventoryIcon from "@mui/icons-material/Inventory";
import DescriptionIcon from "@mui/icons-material/Description";
import SettingsIcon from "@mui/icons-material/Settings";
import Settings from "layouts/settings";
import PrintLaporan from "layouts/printLaporan";
import { Print, WindowIcon } from "@mui/icons-material";
import LaporanForPrint from "layouts/printLaporan/LaporanForPrint";

const role = localStorage.getItem("roles");

let routes;

role === "super_admin"
  ? (routes = [
      {
        type: "collapse",
        name: "Dashboard",
        key: "dashboard",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "dashboard",
        role: "super_admin",
        component: <Dashboard />,
      },
      {
        type: "collapse",
        name: "Laporan Bulanan",
        key: "laporan-bulanan",
        icon: <Print />,
        route: "laporan-bulanan",
        role: "super_admin",
        component: <Laporan />,
      },
      {
        type: "collapse",
        name: "Akses Pengguna",
        key: "akses-pengguna",
        icon: <Icon fontSize="small">table_view</Icon>,
        route: "akses-pengguna",
        role: "super_admin",
        component: <AksesPengguna />,
      },
      {
        type: "collapse",
        name: "Site",
        key: "site",
        icon: <AccountTreeIcon />,
        route: "site",
        role: "super_admin",
        component: <Site />,
      },
      {
        type: "collapse",
        name: "Produk",
        key: "produk",
        icon: <InventoryIcon />,
        route: "produk",
        role: "super_admin",
        component: <Produk />,
      },
      {
        type: "collapse",
        name: "Pengaturan",
        key: "pengaturan",
        icon: <SettingsIcon />,
        route: "pengaturan",
        component: <Settings />,
      },
      // {
      //   type: "collapse",
      //   name: "Sign Up",
      //   key: "sign-up",
      //   icon: <Icon fontSize="small">assignment</Icon>,
      //   route: "/authentication/lupa-password",
      //   component: <Cover />,
      // },
    ])
  : (routes = [
      {
        type: "collapse",
        name: "Data Harian",
        key: "dashboard",
        icon: <Icon fontSize="small">dashboard</Icon>,
        route: "/dashboard",
        role: "site_admin",
        component: <LaporanHarian />,
      },
      {
        type: "collapse",
        name: "Data Bulanan",
        key: "data-bulanan",
        icon: <Icon fontSize="small">assignment</Icon>,
        route: "/data-bulanan",
        role: "site_admin",
        component: <DaftarLaporan />,
      },
      {
        type: "collapse",
        name: "Laporan Bulanan",
        key: "laporan-bulanan",
        icon: <Print />,
        route: "/laporan-bulanan",
        role: "site_admin",
        component: <PrintLaporan />,
      },
      // {
      //   type: "",
      //   name: "Print",
      //   key: "laporan",
      //   icon: <Print />,
      //   route: "/laporan",
      //   role: "site_admin",
      //   component: <LaporanForPrint />,
      // },
      {
        type: "collapse",
        name: "Pengaturan",
        key: "pengaturan",
        icon: <SettingsIcon />,
        route: "pengaturan",
        role: "site_admin",
        component: <Settings />,
      },
    ]);

export default routes;
