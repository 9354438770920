/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data

// Dashboard components
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useMemo, useState } from "react";
import { getData } from "utils/fetch";
import { toast } from "react-toastify";
import LaporanForPrint from "./LaporanForPrint";
import FormAturTanggal from "./FormAturTanggal";
import moment from "moment";

import MDButton from "components/MDButton";

function Dashboard() {
  const [tanggal, setTanggal] = useState([]);
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [isTampilkan, setIsTampilkan] = useState(false);
  const [form, setForm] = useState({
    tanggal: moment().format("YYYY-MM-DD"),
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await getData(
          `/api/super_admin/laporan_hari_ini`,
          form
        );
        if (response) {
          const _temp = [...response.data_laporan];
          let _tempItem;
          _temp?.forEach((item) => {
            _tempItem = Object.keys(item);
          });
          setTanggal(response.tanggal_cetak);
          setItems(_tempItem);
          setData(response.data_laporan);
        }
      } catch (error) {
        toast.error('Tidak ada data.');
      }
    };
    fetch();
  }, [form]);

  const columns = useMemo(() => {
    if (data.length !== 0) {
      const col = [];
      items?.forEach((item, i) => {
        col.push({
          Header: data[0][item].header,
          accessor: data[0][item].accessor,
          align: data[0][item].accessor === "site" ? "left" : "center",
        });
      });
      return col;
    }
  }, [data]);

  const rows = useMemo(() => {
    if (data.length !== 0) {
      const rows = [];
      let obj = {};
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < items.length; j++) {
          obj[data[i][items[j]].accessor] = (
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color={"text"}
              fontWeight="medium"
            >
              {data[i][items[j]].rowBody}
            </MDTypography>
          );
        }
        rows.push({ ...obj });
      }
      return rows;
    }
  }, [data]);

  const handleClick = () => {
    var content = document.getElementById("printarea");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    document.title = `Laporan`;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <iframe
        id="ifmcontentstoprint"
        style={{
          height: "0px",
          width: "0px",
          position: "absolute",
        }}
      ></iframe>
      {data.length !== 0 && (
        <>
          <LaporanForPrint data={data} items={items} />
        </>
      )}
      <Grid container spacing={6} className="mb-20">
        <Grid item xs={12} lg={7}>
          <Card>
            <MDBox py={2} px={2}>
              <MDTypography variant="h6" fontWeight="medium">
                Atur Tanggal
              </MDTypography>
            </MDBox>
            <MDBox pb={2} px={2}>
              <MDBox
                component="ul"
                display="flex"
                flexDirection="column"
                p={0}
                m={0}
              >
                <FormAturTanggal form={form} setForm={setForm} />
              </MDBox>
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <MDBox py={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Data Laporan Harian
                </MDTypography>
              </MDBox>
              <MDBox pt={3} pb={3}>
                {data.length === 0 ? (
                  <MDTypography
                    variant="h6"
                    color="text"
                    className="w-full text-center"
                  >
                    Tidak ada data
                  </MDTypography>
                ) : (
                  <div>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                    <MDBox pt={2} px={2} className="hidden lg:block text-center">
                      <MDButton
                        // variant="outlined"
                        size="medium"
                        color="light"
                        className="uppercase"
                        onClick={handleClick}
                      >
                        <Icon>print</Icon>&nbsp;
                        Cetak Laporan
                      </MDButton>
                    </MDBox>
                  </div>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
