import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  Modal,
} from "@mui/material";
import { Box } from "@mui/system";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import FormEdit from "./FormEdit";
import { Delete, Edit } from "@mui/icons-material";
import { pink } from "@mui/material/colors";
import MDBox from "components/MDBox";
import { useMaterialUIController } from "context";
import { deleteData } from "utils/fetch";
import { toast } from "react-toastify";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: "16px",
  borderRadius: "8px",
};

const CardRiwayat = ({
  tanggal,
  site,
  data,
  id,
  setIsUpdate,
  index,
  daftar_produksi,
  daftar_penjualan,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");

  const handleClose = () => setIsOpen(false);
  const handleCloseDelete = () => setOpen(false);

  const handleClickHapus = async (e) => {
    e.preventDefault();
    try {
      const response = await deleteData(`/api/site_admin/laporan_harian/${id}`);
      toast.success(`${response.message}`);
      setIsUpdate(true);
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
    setOpen(false);
  };

  const handleClickEdit = (e) => {
    setIsOpen(true);
    setType("update");
  };

  const handleClickDelete = (e) => {
    setOpen(true);
    setType("delete");
  };

  return (
    <>
      <Modal
        open={isOpen}
        // sx={{
        //   padding: "16px",
        //   borderRadius: "8px",
        // }}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <FormEdit
            type={type}
            setIsOpen={setIsOpen}
            data={data[index]}
            setIsUpdate={setIsUpdate}
          />
        </Box>
      </Modal>
      <Dialog
        open={open}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          borderRadius: "8px",
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="text-center"
          sx={{ paddingTop: "20px", paddingBottom: 0, fontSize: "16px" }}
        >
          Konfirmasi Tindakan
        </DialogTitle>
        <DialogContent
          sx={{
            paddingTop: 0,
            paddingBottom: "20px",
            paddingRight: "24px",
            paddingLeft: "24px",
            fontSize: "16px",
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            className="text-center"
          >
            Anda yakin ingin menghapus ini?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ paddingBottom: "10px", paddingTop: 0 }}
          className="flex flex-col w-full items-center justify-center gap-y-[10px]"
        >
          <MDButton
            variant="contained"
            size="medium"
            color={"error"}
            className="w-full m-0 text-white"
            onClick={handleClickHapus}
          >
            Hapus
          </MDButton>
          <MDButton
            variant="contained"
            size="medium"
            color={"light"}
            className="w-full m-0 text-black"
            onClick={handleCloseDelete}
          >
            Batalkan
          </MDButton>
        </DialogActions>
      </Dialog>

      <MDBox
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        bgColor={darkMode ? "transparent" : "grey-100"}
        borderRadius="lg"
        p={3}
        mt={2}
      >
        <MDBox width="100%" display="flex" flexDirection="column">
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems={{ xs: "flex-start", sm: "center" }}
            flexDirection={{ xs: "column", sm: "row" }}
            mb={2}
          >
            <div className="flex flex-col gap-2">
              <MDTypography
                variant="button"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {tanggal}
              </MDTypography>
            </div>
            <MDBox
              display="flex"
              alignItems="center"
              mt={{ xs: 2, sm: 0 }}
              ml={{ xs: -1.5, sm: 0 }}
            >
              <MDBox mr={1}>
                <MDButton
                  variant="text"
                  color="error"
                  onClick={handleClickDelete}
                >
                  <Icon>delete</Icon>&nbsp;Hapus
                </MDButton>
              </MDBox>
              <MDButton
                variant="text"
                color={darkMode ? "white" : "dark"}
                onClick={handleClickEdit}
              >
                <Icon>edit</Icon>&nbsp;Ubah
              </MDButton>
            </MDBox>
          </MDBox>
          <MDBox mb={1} lineHeight={0}>
            <MDTypography
              mb={3}
              sx={{
                fontWeight: 500,
                fontSize: "0.875rem",
              }}
            >
              Laporan Produksi
            </MDTypography>
            <TableContainer
              sx={{
                borderRadius: 0,
                boxShadow: 0,
              }}>
              <Table 
                size="small"
                aria-label="a dense table"
                >
                <TableBody>
                  {daftar_produksi.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          padding: "6px 16px",
                        }}>
                        <MDTypography
                          sx={{
                            fontWeight: 300,
                            fontSize: "0.875rem",
                          }}
                        >
                          {item.nama}
                        </MDTypography>
                      </TableCell>
                      <TableCell 
                        align="right"
                        sx={{
                          padding: "6px 16px",
                        }}>
                        <MDTypography
                          sx={{
                            fontWeight: 500,
                            fontSize: "0.875rem",
                          }}
                        >
                          {item.jumlah}
                        </MDTypography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MDBox>
          <MDBox mt={2} lineHeight={0}>
            <MDTypography
              mb={3}
              sx={{
                fontWeight: 500,
                fontSize: "0.875rem",
              }}
            >
              Laporan Penjualan
            </MDTypography>
            <TableContainer
              sx={{
                borderRadius: 0,
                boxShadow: 0,
              }}>
              <Table 
                size="small"
                aria-label="a dense table"
                >
                <TableBody>
                  {daftar_penjualan.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{
                          padding: "6px 16px",
                        }}>
                        <MDTypography
                          sx={{
                            fontWeight: 300,
                            fontSize: "0.875rem",
                          }}
                        >
                          {item.nama}
                        </MDTypography>
                      </TableCell>
                      <TableCell 
                        align="right"
                        sx={{
                          padding: "6px 16px",
                        }}>
                        <MDTypography
                          sx={{
                            fontWeight: 500,
                            fontSize: "0.875rem",
                          }}
                        >
                          {item.jumlah}
                        </MDTypography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </MDBox>
        </MDBox>
      </MDBox>
    </>
  );
};

export default CardRiwayat;
