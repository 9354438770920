import { Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MDButton from "components/MDButton";
import dayjs from "dayjs";
import React, { useState } from "react";

const FormAturTanggal = ({ form, setForm }) => {
  const [value, setValue] = useState(dayjs(new Date()));
  const handleChange = (e) => {
    setForm({
      tanggal: `${e.$y}-${e.$M + 1}-${e.$D}`,
    });
    setValue(e);
  };

  // const handleClickKirim = (e) => {
  //   e.preventDefault();
  //   setIsTampilkan(true);
  //   setParams({
  //     ...form,
  //   });
  // };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { width: "100%" },
      }}
      className="flex flex-col gap-[15px] justify-start items-start"
      autoComplete="off"
    >
      <div className="flex flex-row gap-[12px] w-full items-center">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="m-0"
            format="DD/MM/YYYY"
            openTo="day"
            views={["year", "month", "day"]}
            value={value}
            onChange={handleChange}
          />
        </LocalizationProvider>
      </div>
      {/* <div className="w-full">
        <MDButton
          variant="contained"
          disabled={form.tanggal === ""}
          size="medium"
          color={"success"}
          className="w-full"
          onClick={handleClickKirim}
        >
          Tampilkan
        </MDButton>
      </div> */}
    </Box>
  );
};

export default FormAturTanggal;
