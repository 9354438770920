import { Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import { getData } from "utils/fetch";
import LaporanForPrint from "./LaporanForPrint";
import { toast } from "react-toastify";

const FormFilterPrint = () => {
  const [value, setValue] = useState("");
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  const [itemsBelanja, setItemsBelanja] = useState([]);
  const [form, setForm] = useState({
    bulan: "",
    tahun: "",
  });
  const handleChangeDate = (e) => {
    setValue(e);
    setForm({
      bulan: e.$M + 1,
      tahun: e.$y,
    });
  };

  useEffect(() => {
    if (form.bulan !== "" && form.tahun !== "") {
      const fetch = async () => {
        const response = await getData(`/api/site_admin/laporan`, form);
        setData(response);
        setItems(Object.keys(response.daftar_laporan_site[0]));
        setItemsBelanja(Object.keys(response.daftar_belanja[0]));
      };
      fetch().catch((e) => {
        // oh no, the fetch failed! We should do something about it!
        setValue("");
        toast.error(`Tidak ada data.`);
      });
    }
  }, [form]);

  const handleClickKirim = () => {
    var content = document.getElementById("printarea");
    var pri = document.getElementById("ifmcontentstoprint").contentWindow;
    document.title = `Laporan ${data.tanggal}`;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  };

  return (
    <>
      <iframe
        id="ifmcontentstoprint"
        style={{
          height: "0px",
          width: "0px",
          position: "absolute",
        }}
      ></iframe>
      {data.length !== 0 && (
        <LaporanForPrint
          data={data}
          items={items}
          itemsBelanja={itemsBelanja}
        />
      )}
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { width: "100%" },
        }}
        className="flex flex-col gap-[15px] justify-start items-start"
        autoComplete="off"
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="m-0"
            format="MM/YYYY"
            openTo="month"
            views={["year", "month"]}
            value={value}
            onChange={handleChangeDate}
          />
        </LocalizationProvider>
        <div className="w-full">
          <MDButton
            variant="contained"
            size="medium"
            disabled={value === ""}
            color={"success"}
            className="w-full"
            onClick={handleClickKirim}
          >
            Print
          </MDButton>
        </div>
      </Box>
    </>
  );
};

export default FormFilterPrint;
