/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { postData } from "utils/fetch";

function Cover() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setForm({
      email: e.target.value,
    });
  };

  const handleKirim = async (e) => {
    e.preventDefault();
    try {
      const response = await postData("/api/auth/resetPassword", form);
      toast.success(`${response.message}`);
      navigate("/authentication/sign-in");
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  return (
    <BasicLayout>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDTypography
            variant="h4"
            textAlign="center"
            fontWeight="medium"
            color="black"
            mb={4}
          >
            Lupa Kata Sandi
          </MDTypography>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="E-mail"
                name="email"
                fullWidth
                value={form.email}
                onChange={handleChange}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="success"
                disabled={form.email === ""}
                fullWidth
                onClick={handleKirim}
              >
                Ganti Password
              </MDButton>
            </MDBox>
            <MDBox mt={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {/* Don&apos;t have an account?{" "} */}
                <MDTypography
                  component={Link}
                  to="/authentication/sign-in"
                  variant="button"
                  color="text"
                  fontWeight="regular"
                  textGradient
                >
                  Batalkan
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Cover;
