import { Box, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { postData } from "utils/fetch";
import "./style.css";

const FormChangePassword = ({ setIsUpdate }) => {
  const [form, setForm] = useState({
    new_password_1: "",
    new_password_2: "",
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickKirim = async (e) => {
    e.preventDefault();
    try {
      const response = await postData("/api/auth/setNewPassword", form);
      toast.success(`${response.message}`);
      setForm({
        new_password_1: "",
        new_password_2: "",
      });
      setIsUpdate(true);
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { width: "100%" },
      }}
      className="flex flex-col gap-[15px] justify-start items-start"
      autoComplete="off"
    >
      <TextField
        className="m-0"
        required
        id="outlined-required"
        name="new_password_1"
        label="Password Baru"
        type={"password"}
        value={form.new_password_1}
        onChange={handleChange}
      />
      <TextField
        className="m-0"
        required
        id="outlined-required"
        name="new_password_2"
        label="Konfirmasi Password"
        type="password"
        value={form.new_password_2}
        onChange={handleChange}
      />
      <div className="w-full">
        <MDButton
          variant="contained"
          disabled={form.new_password_1 === "" || form.new_password_2 === ""}
          size="medium"
          color={"success"}
          className="w-full"
          onClick={handleClickKirim}
        >
          Ubah
        </MDButton>
      </div>
    </Box>
  );
};

export default FormChangePassword;
