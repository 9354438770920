import { Close } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { getData } from "utils/fetch";
import { putData } from "utils/fetch";
import "./style.css";

const FormEdit = ({ type, setIsOpen, data, setIsUpdate }) => {
  const [options, setOptions] = useState([]);
  const [form, setForm] = useState({
    nama: data.nama,
    siteId: data?.siteId,
    email: data.email,
  });

  const handleClickKirim = async (e) => {
    e.preventDefault();
    try {
      const response = await putData(
        `/api/super_admin/pengguna/${data.id}`,
        form
      );
      toast.success(`${response.message}`);
      setIsOpen(false);
      setIsUpdate(true);
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  useEffect(() => {
    const fetch = async () => {
      const response = await getData("/api/super_admin/site");
      const _temp = [];
      response.content.forEach((item) => {
        _temp.push({
          value: item.id,
          label: item.nama,
        });
      });
      setOptions(_temp);
    };

    fetch();
  }, []);

  const onSelect = (e) => {
    setForm({
      ...form,
      siteId: e.value,
    });
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { width: "100%" },
      }}
      className="flex flex-col gap-y-[10px] justify-start items-start"
      autoComplete="off"
    >
      {type === "update" && (
        <>
          <div className="w-full flex justify-between items-center">
            <MDTypography sx={{ fontWeight: 700 }}>Perbarui Akses</MDTypography>
            <Close
              onClick={() => setIsOpen(false)}
              className="cursor-pointer text-black font-700"
            />
          </div>
          <TextField
            className="m-0"
            // required
            id="outlined-required"
            value={form.nama}
            name="nama"
            onChange={handleChange}
          />
          <TextField
            className="m-0"
            // required
            name="email"
            type="email"
            id="outlined-required"
            value={form.email}
            onChange={handleChange}
          />
          <Select
            className="w-full text-[16px] rounded-[3px] min-h-[44px] text-[#344767] font-400"
            classNamePrefix="h-full"
            options={options}
            value={options.filter((item) => item.value === form.siteId)}
            onChange={onSelect}
            styles={{
              placeholder: (defaultStyles) => ({
                ...defaultStyles,
                fontSize: "0.875rem",
              }),
              option: (defaultStyles) => ({
                ...defaultStyles,
                fontSize: "0.875rem",
              }),
              control: (defaultStyles) => ({
                ...defaultStyles,
                fontSize: "0.875rem",
              }),
              singleValue: (defaultStyles) => ({
                ...defaultStyles,
                fontSize: "0.875rem",
              }),
            }}
          />
          <div className="w-full">
            <MDButton
              variant="contained"
              size="medium"
              color={"info"}
              className="w-full uppercase"
              onClick={handleClickKirim}
            >
              Perbarui
            </MDButton>
          </div>
        </>
      )}
    </Box>
  );
};

export default FormEdit;
