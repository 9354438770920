import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import CardSite from "./CardSite";
import MDTypography from "components/MDTypography";
import FormAddSite from "./FormAddSite";
import { getData } from "utils/fetch";
import Footer from "examples/Footer";

const Site = () => {
  const [data, setData] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      const response = await getData("/api/super_admin/site");
      setData(response.content);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (isUpdate) {
      const fetch = async () => {
        const response = await getData("/api/super_admin/site");
        setData(response.content);
      };
      fetch();
    }
    setIsUpdate(false);
  }, [isUpdate]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={7}>
            <Card>
              <MDBox py={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium">
                  Buat Site Baru
                </MDTypography>
              </MDBox>
              <MDBox pb={2} px={2}>
                <MDBox
                  component="ul"
                  display="flex"
                  flexDirection="column"
                  p={0}
                  m={0}
                >
                  <FormAddSite setIsUpdate={setIsUpdate} />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} lg={7}>
            <Card>
              <MDBox pt={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium">
                  Daftar Site
                </MDTypography>
              </MDBox>
              {data.length === 0 ? (
                <MDBox pb={2} px={2}>
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                  >
                    <MDBox
                      component="li"
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      bgColor={"grey-100"}
                      className="animate-pulse h-[120px]"
                      borderRadius="lg"
                      p={3}
                      mt={2}
                    ></MDBox>
                  </MDBox>
                </MDBox>
              ) : (
                <MDBox pb={2} px={2}>
                  <MDBox
                    component="ul"
                    display="flex"
                    flexDirection="column"
                    p={0}
                    m={0}
                  >
                    {data.map((item, i) => (
                      <CardSite
                        index={i}
                        key={item?.id}
                        id={item?.id}
                        namaSite={item?.nama}
                        data={data}
                        setIsUpdate={setIsUpdate}
                      />
                    ))}
                  </MDBox>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Site;
