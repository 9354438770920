/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import { postData } from "utils/fetch";
import { toast } from "react-toastify";

function SignIn() {
  const [formLogin, setrFormLogin] = useState({
    email: "",
    password: "",
  });
  // const navigate = useNavigate();

  const handleChange = (e) => {
    setrFormLogin({
      ...formLogin,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postData("/api/auth/signIn", formLogin);
      localStorage.setItem("roles", response.role);
      localStorage.setItem("token", response.token);
      window.location.replace("/dashboard");
      // navigate("/dashboard");
    } catch (error) {
      toast.error("Email dan Password Salah");
    }
  };

  return (
    <BasicLayout>
      <Card>
        <MDBox pt={4} pb={3} px={3}>
          <MDTypography
            variant="h4"
            textAlign="center"
            fontWeight="medium"
            color="black"
            mb={4}
          >
            Login
          </MDTypography>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="E-mail"
                name="email"
                onChange={handleChange}
                value={formLogin.email}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Kata sandi"
                name="password"
                onChange={handleChange}
                value={formLogin.password}
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                type="submit"
                variant="gradient"
                color="success"
                fullWidth
              >
                Masuk
              </MDButton>
            </MDBox>
            <MDBox mt={1} textAlign="center">
              <MDTypography variant="button" color="text">
                {/* Don&apos;t have an account?{" "} */}
                <MDTypography
                  component={Link}
                  to="/authentication/lupa-password"
                  variant="button"
                  color="black"
                  fontWeight="regular"
                  textGradient
                >
                  Lupa password?
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
