import { Box, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { postData } from "utils/fetch";
import { getData } from "utils/fetch";
import "./style.css";

const FormAddSite = ({ setIsUpdate }) => {
  const [options, setOptions] = useState([]);
  const [formAddSite, setFormAddSite] = useState({
    nama: "",
    user_id: "",
  });

  useEffect(() => {
    const fetch = async () => {
      const response = await getData("/api/super_admin/pengguna");
      const _temp = [];
      response.content.forEach((item) => {
        _temp.push({
          value: item.id,
          label: item.nama,
        });
      });
      setOptions(_temp);
    };

    fetch();
  }, []);

  const handleChange = (e) => {
    setFormAddSite({
      ...formAddSite,
      [e.target.name]: e.target.value,
    });
  };

  const onSelect = (e) => {
    setFormAddSite({
      ...formAddSite,
      user_id: e.value,
    });
  };

  const handleClickKirim = async (e) => {
    e.preventDefault();
    try {
      const response = await postData("/api/super_admin/site", formAddSite);
      toast.success(`${response.message}`);
      setFormAddSite({
        nama: "",
        user_id: "",
      });
      setIsUpdate(true);
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { width: "100%" },
      }}
      className="flex flex-col gap-[15px] justify-start items-start"
      autoComplete="off"
    >
      <TextField
        className="m-0"
        required
        id="outlined-required"
        name="nama"
        label="Nama"
        value={formAddSite.nama}
        onChange={handleChange}
      />
      <Select
        className="w-full text-[#7B809A]"
        classNamePrefix="h-full"
        placeholder="Pilih Admin"
        value={options.filter((item) => item.value === formAddSite.user_id)}
        onChange={onSelect}
        options={options}
        styles={{
          placeholder: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: "0.875rem",
          }),
          option: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: "0.875rem",
          }),
          control: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: "0.875rem",
          }),
          singleValue: (defaultStyles) => ({
            ...defaultStyles,
            fontSize: "0.875rem",
          }),
        }}
      />
      <div className="w-full">
        <MDButton
          variant="contained"
          disabled={formAddSite.nama === ""}
          size="medium"
          color={"success"}
          className="w-full"
          onClick={handleClickKirim}
        >
          Tambah
        </MDButton>
      </div>
    </Box>
  );
};

export default FormAddSite;
