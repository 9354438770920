import { Box, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { postData } from "utils/fetch";
import { getData } from "utils/fetch";
import { toast } from "react-toastify";

const FormLaporan = ({ setIsUpdate }) => {
  const [value, setValue] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [product, setProduct] = useState([]);
  const [form, setForm] = useState({
    bulan: "",
    tahun: "",
    daftar_belanja: [],
  });

  useEffect(() => {
    const fetch = async () => {
      const response = await getData("/api/site_admin/produk");
      const _temp = [...response.content];
      const _tempBelanja = { ...form };
      _temp.forEach((item) => {
        _tempBelanja.daftar_belanja.push({
          id_produk: item.id,
          jumlah: "",
        });
      });
      setForm(_tempBelanja);
      setProduct(response.content);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (isSubmit) {
      const fetch = async () => {
        const response = await getData("/api/site_admin/produk");
        const _temp = [...response.content];
        const _tempBelanja = { ...form };
        _temp.forEach((item) => {
          _tempBelanja.daftar_belanja.push({
            id_produk: item.id,
            jumlah: "",
          });
        });
        setForm(_tempBelanja);
        setProduct(response.content);
      };
      fetch();
    }
    setIsSubmit(false);
  }, [isSubmit]);

  const handleChange = (e, id) => {
    const _temp = { ...form };
    _temp.daftar_belanja.forEach((item) => {
      if (item.id_produk === id) {
        item.jumlah = e.target.value;
      }
    });
    setForm(_temp);
  };

  const handleChangeDate = (e) => {
    setForm({
      ...form,
      bulan: e.$M + 1,
      tahun: e.$y,
    });
    setValue(e);
  };

  const handleClickKirim = async (e) => {
    e.preventDefault();
    try {
      const response = await postData("/api/site_admin/belanja_bulanan", form);
      toast.success(`${response.message}`);
      setForm({
        bulan: "",
        tahun: "",
        daftar_belanja: [],
      });
      setValue("");
      setIsUpdate(true);
      setIsSubmit(true);
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { width: "100%" },
      }}
      className="flex flex-col gap-[15px] justify-start items-start"
      autoComplete="off"
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          className="m-0"
          format="MM/YYYY"
          openTo="month"
          views={["year", "month"]}
          value={value}
          onChange={handleChangeDate}
        />
      </LocalizationProvider>
      {product.length === 0 ? (
        <TextField className="m-0" id="outlined-required" />
      ) : (
        product.map((item, index) => (
          <TextField
            key={item.id}
            className="m-0"
            required
            type="number"
            id="outlined-required"
            label={item.nama}
            value={form.daftar_belanja[index]?.jumlah || ""}
            onChange={(e) => handleChange(e, item.id)}
          />
        ))
      )}

      <div className="w-full">
        <MDButton
          variant="contained"
          size="medium"
          disabled={
            form.bulan === "" ||
            form.tahun === "" ||
            Object.values(form.daftar_belanja)
              .map((item) => item.jumlah === "")
              .some((item) => item === true)
          }
          color={"success"}
          className="w-full"
          onClick={handleClickKirim}
        >
          Simpan
        </MDButton>
      </div>
    </Box>
  );
};

export default FormLaporan;
