import { Box, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { postData } from "utils/fetch";
import "./style.css";

const FormTambahProduk = ({ setIsUpdate }) => {
  const [form, setForm] = useState({
    nama: "",
  });

  const handleChange = (e) => {
    setForm({
      nama: e.target.value,
    });
  };

  const handleClickKirim = async (e) => {
    e.preventDefault();
    try {
      const response = await postData("/api/super_admin/produk", form);
      toast.success(`${response.message}`);
      setIsUpdate(true);
      setForm({
        nama: "",
      });
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { width: "100%" },
      }}
      className="flex flex-col gap-[15px] justify-start items-start"
      autoComplete="off"
    >
      <TextField
        className="m-0"
        required
        id="outlined-required"
        value={form.nama}
        onChange={handleChange}
        label="Nama Produk"
      />
      <div className="w-full">
        <MDButton
          variant="contained"
          disabled={form.nama === ""}
          size="medium"
          color={"success"}
          className="w-full"
          onClick={handleClickKirim}
        >
          Tambah
        </MDButton>
      </div>
    </Box>
  );
};

export default FormTambahProduk;
