import { Close } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { putData } from "utils/fetch";
import "./style.css";

const FormEdit = ({ type, setIsOpen, data, setIsUpdate }) => {
  const [form, setForm] = useState({
    nama: data.nama,
  });

  const handleClickKirim = async (e) => {
    e.preventDefault();
    try {
      const response = await putData(
        `/api/super_admin/produk/${data.id}`,
        form
      );
      toast.success(`${response.message}`);
      setIsOpen(false);
      setIsUpdate(true);
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  const handleChange = (e) => {
    setForm({
      nama: e.target.value,
    });
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { width: "100%" },
      }}
      className="flex flex-col gap-y-[10px] justify-start items-start"
      autoComplete="off"
    >
      {type === "update" && (
        <>
          <div className="w-full flex justify-between items-center">
            <MDTypography sx={{ fontWeight: 700 }}>
              Perbarui Produk
            </MDTypography>
            <Close
              onClick={() => setIsOpen(false)}
              className="cursor-pointer text-black font-700"
            />
          </div>
          <TextField
            className="m-0"
            // required
            id="outlined-required"
            value={form.nama}
            onChange={handleChange}
          />
          <div className="w-full">
            <MDButton
              variant="contained"
              size="medium"
              color={"info"}
              className="w-full uppercase"
              onClick={handleClickKirim}
            >
              Perbarui
            </MDButton>
          </div>
        </>
      )}
    </Box>
  );
};

export default FormEdit;
