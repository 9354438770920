/* eslint-disable multiline-ternary */
/* eslint-disable no-return-await */
import axios from "../configs";

export async function getData(url, params) {
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";

  return await axios.get(url, {
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function postData(url, payload, formData) {
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";

  return await axios.post(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": formData ? "multipart/form-data" : "application/json",
    },
  });
}

export async function putData(url, payload, formData) {
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";

  return await axios.put(url, payload, {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": formData ? "multipart/form-data" : "application/json",
    },
  });
}

export async function deleteData(url) {
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";

  return await axios.delete(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
