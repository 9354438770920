import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import FormFilterPrint from "./FormFilterPrint";

const PrintLaporan = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} lg={7}>
            <Card>
              <MDBox py={2} px={2}>
                <MDTypography variant="h6" fontWeight="medium">
                  Atur tanggal
                </MDTypography>
              </MDBox>
              <MDBox pb={2} px={2}>
                <MDBox
                  component="ul"
                  display="flex"
                  flexDirection="column"
                  p={0}
                  m={0}
                >
                  <FormFilterPrint />
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default PrintLaporan;
