import { Box, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { postData } from "utils/fetch";
import { getData } from "utils/fetch";
import { toast } from "react-toastify";
import MDTypography from "components/MDTypography";

const FormLaporan = ({ setIsUpdate }) => {
  const [value, setValue] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [product, setProduct] = useState([]);
  const [form, setForm] = useState({
    tanggal: "",
    daftar_penjualan: [],
    daftar_produksi: [],
  });

  useEffect(() => {
    const fetch = async () => {
      const response = await getData("/api/site_admin/produk");
      const _temp = [...response.content];
      const _tempBelanja = { ...form };
      _temp.forEach((item) => {
        _tempBelanja.daftar_penjualan.push({
          id_produk: item.id,
          jumlah: "",
        });
        _tempBelanja.daftar_produksi.push({
          id_produk: item.id,
          jumlah: "",
        });
      });
      setForm(_tempBelanja);
      setProduct(response.content);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (isSubmit) {
      const fetch = async () => {
        const response = await getData("/api/site_admin/produk");
        const _temp = [...response.content];
        const _tempBelanja = { ...form };
        _temp.forEach((item) => {
          _tempBelanja.daftar_penjualan.push({
            id_produk: item.id,
            jumlah: "",
          });
          _tempBelanja.daftar_produksi.push({
            id_produk: item.id,
            jumlah: "",
          });
        });
        setForm(_tempBelanja);
        setProduct(response.content);
      };
      fetch();
    }
    setIsSubmit(false);
  }, [isSubmit]);

  const handleChange = (e, id, selector) => {
    const _temp = { ...form };
    switch (selector) {
      case "penjualan":
        _temp.daftar_penjualan.forEach((item) => {
          if (item.id_produk === id) {
            item.jumlah = e.target.value;
          }
        });
        setForm(_temp);
        break;
      case "produksi":
        _temp.daftar_produksi.forEach((item) => {
          if (item.id_produk === id) {
            item.jumlah = e.target.value;
          }
        });
        setForm(_temp);
        break;
      default:
        break;
    }
  };

  const handleChangeDate = (e) => {
    setForm({
      ...form,
      tanggal: `${e.$D}-${e.$M + 1}-${e.$y}`,
    });
    setValue(e);
  };

  const handleClickKirim = async (e) => {
    e.preventDefault();
    try {
      const response = await postData("/api/site_admin/laporan_harian", form);
      toast.success(`${response.message}`);
      setIsUpdate(true);
      setForm({
        tanggal: "",
        daftar_penjualan: [],
        daftar_produksi: [],
      });
      setValue("");
      setIsSubmit(true);
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { width: "100%" },
      }}
      className="flex flex-col gap-[15px] justify-start items-start"
      autoComplete="off"
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          className="m-0"
          format="DD/MM/YYYY"
          openTo="day"
          views={["year", "month", "day"]}
          value={value}
          onChange={handleChangeDate}
        />
      </LocalizationProvider>

      <MDTypography
        className="mb-3"
        sx={{
          fontWeight: 500,
          fontSize: "0.875rem",
        }}
      >
        Laporan Produksi
      </MDTypography>
      {product.length === 0 ? (
        <TextField className="m-0" id="outlined-required" />
      ) : (
        product.map((item, index) => (
          <TextField
            key={"daftar-produksi-" + item.id}
            className="m-0 mb-3"
            required
            type="number"
            id="outlined-required"
            label={item.nama}
            value={form.daftar_produksi[index]?.jumlah || ""}
            onChange={(e) => handleChange(e, item.id, "produksi")}
          />
        ))
      )}

      <MDTypography
        className="mb-3"
        sx={{
          fontWeight: 500,
          fontSize: "0.875rem",
        }}
      >
        Laporan Penjualan
      </MDTypography>
      {product.length === 0 ? (
        <TextField className="m-0" id="outlined-required" />
      ) : (
        product.map((item, index) => (
          <TextField
            key={"daftar-penjualan-" + item.id}
            className="m-0 mb-3"
            required
            type="number"
            id="outlined-required"
            label={item.nama}
            value={form.daftar_penjualan[index]?.jumlah || ""}
            onChange={(e) => handleChange(e, item.id, "penjualan")}
          />
        ))
      )}

      <div className="w-full">
        <MDButton
          variant="contained"
          disabled={
            form.tanggal === "" ||
            Object.values(form.daftar_penjualan)
              .map((item) => item.jumlah === "")
              .some((item) => item === true) ||
            Object.values(form.daftar_produksi)
              .map((item) => item.jumlah === "")
              .some((item) => item === true)
          }
          size="medium"
          color={"success"}
          className="w-full"
          onClick={handleClickKirim}
        >
          Simpan
        </MDButton>
      </div>
    </Box>
  );
};

export default FormLaporan;
