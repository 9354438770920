// eslint-disable-next-line consistent-return
export default function errorHandler(error) {
  // console.log('error handler', error);
  /**
   * todo mapping berdasarkan status error
   */
  if (error) {
    if (error.response) {
      if (
        error.response.status === 401 &&
        error.response.data.message !== "Bad credentials"
      ) {
        localStorage.clear();
        window.location.href = "/authentication/sign-in";
      }
      return Promise.reject(error);
    }
  }
}
