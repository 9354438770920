import * as React from "react";
import { Navigate, Outlet } from "react-router-dom";

export default function GuestOnlyRoute({ children }) {
  const token = localStorage.getItem("token");

  if (!token) return <Navigate to="/authentication/sign-in" replace={true} />;

  return children || <Outlet />;
}
