import { Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MDButton from "components/MDButton";
import React, { useState } from "react";

const FormAturTanggal = ({ form, setForm, setIsTampilkan, setParams }) => {
  const [value, setValue] = useState("");
  const handleChange = (e) => {
    setForm({
      bulan: e.$M + 1,
      tahun: e.$y,
    });
    setValue(e);
  };

  const handleClickKirim = (e) => {
    e.preventDefault();
    setIsTampilkan(true);
    setParams({
      ...form,
    });
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { width: "100%" },
      }}
      className="flex flex-col gap-[15px] justify-start items-start"
      autoComplete="off"
    >
      <div className="flex flex-row gap-[12px] w-full items-center">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            className="m-0"
            format="MM/YYYY"
            openTo="month"
            views={["year", "month"]}
            value={value}
            onChange={handleChange}
          />
        </LocalizationProvider>
      </div>
      <div className="w-full">
        <MDButton
          variant="contained"
          disabled={form.bulan === "" || form.tahun === ""}
          size="medium"
          color={"success"}
          className="w-full"
          onClick={handleClickKirim}
        >
          Perbarui
        </MDButton>
      </div>
    </Box>
  );
};

export default FormAturTanggal;
