import { Close } from "@mui/icons-material";
import { Box, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import dayjs from "dayjs";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { putData } from "utils/fetch";

const FormEdit = ({ type, setIsOpen, data, setIsUpdate }) => {
  const [value, setValue] = useState(dayjs(data.tanggal));
  const [form, setForm] = useState({
    tanggal: data.tanggal,
    daftar_produksi: data.daftar_produksi,
    daftar_penjualan: data.daftar_penjualan,
  });

  const handleChangeDate = (e) => {
    setForm({
      ...form,
      tanggal: `${e.$D}-${e.$M + 1}-${e.$y}`,
    });
    setValue(e);
  };

  const handleChange = (e, id, selector) => {
    const _temp = { ...form };
    switch (selector) {
      case 'penjualan':
        _temp.daftar_penjualan.forEach((item) => {
          if (item.id === id) {
            item.jumlah = e.target.value;
          }
        });
        setForm(_temp);
        break;
      case 'produksi':
        _temp.daftar_produksi.forEach((item) => {
          if (item.id === id) {
            item.jumlah = e.target.value;
          }
        });
        setForm(_temp);
        break;
      default:
        break;
    }
  };

  const handleClickKirim = async (e) => {
    e.preventDefault();
    try {
      const response = await putData(
        `api/site_admin/laporan_harian/${data.id}`,
        form
      );
      toast.success(`${response.message}`);
      setIsUpdate(true);
    } catch (error) {
      toast.error(`${error.response.data.message}`);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { width: "100%" },
      }}
      className="flex flex-col gap-y-[10px] justify-start items-start"
      autoComplete="off"
    >
      {type === "update" && (
        <>
          <div className="w-full flex justify-between items-center">
            <MDTypography sx={{ fontWeight: 700 }}>
              Perbarui Laporan
            </MDTypography>
            <Close
              onClick={() => setIsOpen(false)}
              className="cursor-pointer text-black font-700"
            />
          </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              className="m-0"
              format="DD/MM/YYYY"
              openTo="day"
              views={["year", "month", "day"]}
              value={value}
              onChange={handleChangeDate}
            />
          </LocalizationProvider>

          <MDTypography
            mb={1}
            sx={{
              fontWeight: 500,
              fontSize: "0.875rem",
            }}
          >
            Laporan Produksi
          </MDTypography>
          
          {data.daftar_produksi.map((item) => (
            <TextField
              key={'daftar-produksi-' + item.id}
              className="m-0"
              required
              type="number"
              label={item.nama}
              id="outlined-required"
              value={item.jumlah}
              onChange={(e) => handleChange(e, item.id, 'produksi')}
            />
          ))}

          <MDTypography
            mb={1}
            sx={{
              fontWeight: 500,
              fontSize: "0.875rem",
            }}
          >
            Laporan Penjualan
          </MDTypography>
          
          {data.daftar_penjualan.map((item) => (
            <TextField
              key={'daftar-penjualan-' + item.id}
              className="m-0"
              required
              type="number"
              label={item.nama}
              id="outlined-required"
              value={item.jumlah}
              onChange={(e) => handleChange(e, item.id, 'penjualan')}
            />
          ))}

          <div className="w-full">
            <MDButton
              variant="contained"
              size="medium"
              color={"info"}
              className="w-full uppercase"
              onClick={handleClickKirim}
            >
              Perbarui
            </MDButton>
          </div>
        </>
      )}
    </Box>
  );
};

export default FormEdit;
